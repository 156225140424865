import {
  red,
  green,
  teal,
  deepOrange,
  deepPurple,
  cyan,
} from "@mui/material/colors";
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // LIGHT MODE
          primary: {
            main: `#e0e0e0`,
            dark: `#dcdcdc`,
            light: `#707070`,
          },
          secondary: {
            main: teal[800],
            dark: teal[900],
            light: teal[600],
          },

          info: {
            main: `#fff`,
            dark: `#f4f4f4`,
            light: `#757474`,
          },
          text: {
            primary: "#001613",
            secondary: "#001613",
          },
          background: {
            default: "#ffffff",
            paper: "#ffffff",
          },
        }
      : {
          // DARK MODE
          primary: {
            main: `#ffbb33`,
            dark: `#b28223`,
            light: `#ffc85b`,
          },
          secondary: {
            main: `#ffeb3b`,
            dark: `#b2a429`,
            light: `#ffef62`,
          },
          info: {
            main: `rgba( 20, 25, 34, 0.9)`,
            dark: `rgba(0, 169, 244, 0.4)`,
            light: `rgba( 20, 25, 34, 0.9)`,
          },
          background: {
            default: "#131d32",
            paper: "#1c2a48",
          },
          text: {
            primary: "#ffffff",
            secondary: "#bdbdbd",
          },
        }),
  },
});

export default getDesignTokens;
