import React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import getDesignTokens from "../../../constants/theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import siteImage from "../../../assets/images/13.jpg";
import { teal } from "@mui/material/colors";

const MainBackdrop = ({ children }) => {
  const [mode, setMode] = React.useState("light");
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{
            color: "#218C6F",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "block",
            backgroundImage: `url(${siteImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          open={true}
          className="page-backdrop">
          <Box
            className="backdrop-gradient2"
            sx={{
              p: { md: 0, sm: 0 },
              mt: { xs: 0, md: 0 },
              mb: -0.7,
              height: `100%`,
            }}>
            <Box sx={{ display: "flex", bgcolor: "secondary.dark" }}>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                mt: 15,
              }}>
              <CircularProgress
                sx={{ color: teal[600], position: "absolute", top: `25%` }}
                size={220}
                thickness={9}
              />
            </Box>
            <Box
              sx={{
                justifyContent: "flex-start",
                p: 2,
                mt: 15,
              }}>
              <Typography
                className="site-text"
                variant="h5"
                color="text.primary"
                sx={{ position: "absolute", top: `68%`, fontWeight: 600 }}>
                Welcome to Thur BioTech
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}>
                <Typography
                  className="landing-text"
                  //   variant="h2"
                  sx={{
                    position: "absolute",
                    top: `70%`,
                    mt: 1,
                    fontSize: { xs: 50, md: 60 },
                    color: "text.primary",
                    fontWeight: 800,
                    cursor: "pointer",
                  }}>
                  WAIT A BIT
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Typography
                className="site-text footer"
                color="text.primary"
                sx={{ position: `absolute`, fontSize: 10 }}>
                Copyright © 2023 Thur BioTech
              </Typography>
            </Box>
          </Box>
        </Backdrop>
      </ThemeProvider>
    </>
  );
};
MainBackdrop.propTypes = {
  color: PropTypes.string,
  open: PropTypes.bool,
  appLayer: PropTypes.bool,
};

export default MainBackdrop;
