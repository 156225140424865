/*!

=========================================================
* ABRAHAM MITIKU - v2.0.0
=========================================================

* Copyright 2022 ABRAHAM MITIKU
* Coded by:  Abraham Mitiku

=========================================================

*
*/
/**
 * Public Views
 * !Public view pages should be imported here and add with public view categories
 */
import React, { Suspense, lazy } from "react";
import MainBackdrop from "../components/layouts/feedbacks/MainBackdrop";
//import AboutBackdrop from "../components/layouts/feedbacks/AboutBackdrop";
//import ContactBackdrop from "../components/layouts/feedbacks/ContactBackdrop";

const NotFound = lazy(() => import("../components/views/404/NotFound"));

const Home = lazy(() => import("../components/views/home/Home"));
const About = lazy(() => import("../components/views/about/About"));
const Product = lazy(() => import("../components/views/product/Product"));
const Contact = lazy(() => import("../components/views/contact/Contact"));
//const Blog = lazy(() => import('../components/views/blog/Blog'));
// layout
const PublicLayout = lazy(() => import("../components/layouts/PublicLayout"));

const routes = [
  /**
   *  Public Views
   *  Directories:
   *  Views -> public - > [Home]
   */
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<MainBackdrop open={true} backdrop={true} />}>
        <PublicLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Home />{" "}
          </Suspense>
        ),
      },
      {
        path: "/home",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Home />{" "}
          </Suspense>
        ),
      },
      {
        path: "/about",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <About />{" "}
          </Suspense>
        ),
      },
      {
        path: "/products",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Product />{" "}
          </Suspense>
        ),
      },
      {
        path: "/contact",
        element: (
          <Suspense fallback={<MainBackdrop />}>
            <Contact />{" "}
          </Suspense>
        ),
      },
    ],
  },
];
export default routes;
